import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SiteDownPage from './SiteDownPage';
import UrlConverter from './UrlConverter';

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        history('/login');
        return;
      }
      
      try {
        const response = await fetch('https://api.bucksburst.com/auth/adjust', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          history('/login');
        }
      } catch (error) {
        console.error('Authentication error:', error);
        setIsAuthenticated(false);
        setError(true); // Set error to true if an error occurs during authentication
      }
    };
    
    validateToken();
  }, [history]);

  if (error) {
    // If authentication error occurs, render the site down page
    return <SiteDownPage />;
  }

  return isAuthenticated ? <UrlConverter /> : null; // Render UrlConverter if authenticated
};

export default ProtectedRoute;
