import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CallbackComponent = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  console.log(code)

  useEffect(() => {
    if (code) {
      fetch('https://api.bucksburst.com/auth/discord/callback', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success && data.token) {
            localStorage.setItem('token', data.token);
            navigate('/');
          } else {
            navigate('/login');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          navigate('/login');
        });
    }
  }, [code, navigate]);
  

  return <div style={{backgroundColor: "gray", color: "white"}}>Processing authentication...</div>;
};

export default CallbackComponent;