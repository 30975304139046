import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SiteDownPage from './SiteDownPage';
import './protected.css';


const Protected = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoading(false); // No token available, authentication not attempted
        return;
      }
      
      try {
        const response = await fetch('https://api.bucksburst.com/auth/adjust', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          setIsAuthenticated(true); // Authentication successful
        }
      } catch (error) {
        console.error('Authentication error:', error);
        setError(true); // Error occurred during authentication
      } finally {
        setLoading(false); // Regardless of success or failure, stop loading
      }
    };
    
    validateToken();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  if (error) {
    // If authentication failed or error occurred, render the site down page
    return <SiteDownPage />;
  }

  if (isAuthenticated) {
    // If authenticated, redirect to home page or any other protected route
    history('/');
    return null;
  }

  // If not authenticated, render the login prompt
  return (
    <div className='bodyfr'>
      <div className="containerfr">
        <div className="textfr">You are not authenticated, please contact Admin or login below!</div>
        <button
          className="login-button"
          onClick={() => window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1202517446058184756&response_type=code&redirect_uri=https%3A%2F%2Fconvert.adjust.lol%2Fauth%2Fdiscord%2Fcallback&scope=identify'}
        >
          Login to Discord
        </button>
      </div>
    </div>
  );
};

export default Protected;
