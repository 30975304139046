import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import CallbackComponent from './CallbackComponent';
import Protected from './protected';
import './App.css';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Protected />} />
        <Route path="/auth/discord/callback" element={<CallbackComponent />} />
        <Route path="/" element={<ProtectedRoute />} />
      </Routes>
    </Router>
  );
}

export default App;