import React from 'react';
import './protected.css';

const SiteDownPage = () => {
  return (
    <div className='bodyfr'>
      <div className="containerfr">
        <div className="textfr">Sorry, the website is currently experiencing issues. Please contact ducky for it to be resolved.</div>
      </div>
    </div>
  );
};

export default SiteDownPage;
